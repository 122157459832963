import {type ErrorType} from 'Typings/state'
import {useEffect, useState} from 'react'
import * as Sentry from '@sentry/react'
import {baseUrl} from "../Constants/keys";

interface UseGetAPIRequestProps {
  url: string
  options: RequestInit | undefined
  isMutation?: boolean
}

export function useGetAPIRequest<T>({
  url,
  options,
  isMutation = false
}: UseGetAPIRequestProps) {
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState<ErrorType>()
  const [data, setData] = useState<Record<string, T>>()

  const execute = async (data?: Record<string, T>, urlUpdate: string = url) => {
    setIsFetching(true)
    const body = data ? buildBody(data) : undefined
    let res: Response | null = null;
    try {
      res = await fetch(urlUpdate, { ...options, ...body })
    }
    catch(err) {
      setError({data: {
          name: "NetworkError",
          message: "Network error (code 812)",
          code: "812",
        }, status: 500})
      if (urlUpdate !== baseUrl + 'task/list') {
        Sentry.captureException(err, {level: "fatal"})
      }
    }
    finally {
      setIsFetching(false)
    }

    if (!res) {
      return;
    }

    if (res.status > 399) {
      setError({data: {
          name: "NetworkError",
          message: "Network error (code 812)",
          code: "812",
        }, status: res.status})

      Sentry.captureException(new Error("MVerity API returned status: " + res.status), {level: "fatal"})
    } else {
      setData(await res.json())
    }
  }

  useEffect(() => {
    if (!isMutation) execute()
  }, [])

  return { data, isFetching, error, execute }
}

export const buildBody = (obj: Record<string, any>) => ({
  body: JSON.stringify(obj)
})
