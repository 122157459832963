// import { MVODStateContext } from "Services/stateProvider";
import {
  PUBLIC_ROUTES
  // AUTHENTICATED_ROUTES,
  // RESTRICTED_ROUTES,
} from 'Constants/routes'
import { type FC } from 'react'
import { useRoutes } from 'react-router-dom'

const Routes: FC = () => {
  // This will be inuse when authentication needed
  // const { state: { isAuthenticated } } = useContext(MVODStateContext);
  // const routes = isAuthenticated ? AUTHENTICATED_ROUTES : PUBLIC_ROUTES;

  const routes = PUBLIC_ROUTES

  const allRoutes = [...routes]

  return useRoutes(allRoutes)
}

export { Routes }
