import { type FC, type ReactNode } from 'react'

interface BoxedContentProps {
  children?: ReactNode | ReactNode[]
  disabled?: boolean
}

export const BoxedContent: FC<BoxedContentProps> = ({
  children,
  disabled = false
}) => {
  const styles = disabled
    ? 'border-light-borders text-grayed'
    : 'border-primary text-primary'
  return (
    <div
      className={`px-7 py-3 rounded-lg border-2 justify-center items-center mb-4 ${styles}`}
    >
      {children}
    </div>
  )
}
