import { Wrapper } from '../styles'

const Success = () => {
  return (
    <Wrapper>
      <div className="flex flex-col items-center justify-center max-w-[500px]">
        <h1 className="text-center text-dark text-4xl font-bold mb-4">
          Thank you for submitting your photo!
        </h1>
        <p className="text-center text-normal text-lg">
          You should receive a confirmation of your odometer reading within approximately 24 hours.
        </p>
      </div>
    </Wrapper>
  )
}

export default Success
