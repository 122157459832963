import {
  type MVODStateContextType,
  type MVODStateType,
  type MVODStoredState,
  type MVODVolatileState
} from 'Typings/state'

export const STORED_STATE_NAME = 'mvod-cookies'
export const INITIAL_STATE: MVODStateContextType = {
  state: {
    API_KEY: null,
    selectedTask: null
  },
  // eslint-disable-next-line
  updateState: (s: MVODStateType) => null,
  // eslint-disable-next-line
  updateStateItem: (s: MVODVolatileState) => null,
  // eslint-disable-next-line
  updateStoredState: (s: MVODStoredState) => null
}
