import { Alert, Snackbar } from '@mui/material'
import { MVODStateContext } from 'Services/stateProvider'
import { type FC, useContext } from 'react'

interface ToastMessageProps {}

export const ToastMessage: FC<ToastMessageProps> = () => {
  const {
    state: { toastMessage },
    updateStateItem
  } = useContext(MVODStateContext)
  return (
    <Snackbar
      autoHideDuration={3000}
      onClose={() => { updateStateItem({ toastMessage: null }) }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={Boolean(toastMessage && toastMessage.message !== '')}
      key={'toast message'}
    >
      <Alert severity={toastMessage?.color}>{toastMessage?.message}</Alert>
    </Snackbar>
  )
}
