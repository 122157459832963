import styled from '@emotion/styled';

export const VideoWrapper = styled.div({
  top: 0,
  left: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
  backgroundColor: '#E8F4F8'
})

export const MediaContainer = styled.div({
  position: 'absolute',
  top: 0
})

export const ButtonContainer = styled.div({
  bottom: 0,
  margin: 30,
  '@media(orientation: landscape)': {
    margin: '0 0 5px'
  }
})

export const CloseOverlay = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  zIndex: 12
})

export const TopOverlay = styled.div({
  top: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  position: 'absolute'
})

export const GuideOverlay = styled.div({
  marginTop: 30,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  zIndex: 12
})