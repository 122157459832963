import { useEffect, useState } from 'react'

export const useGetOrientation = () => {
  const [getOrientation, setOrientation] = useState('')

  useEffect(() => {
    const handleOrientationChange = (event: {
      matches: boolean
      media: string
    }) => {
      const { matches, media } = event
      if (matches) {
        if (media === '(orientation: portrait)') {
          setOrientation('portrait')
        } else if (media === '(orientation: landscape)') {
          setOrientation('landscape')
        }
      }
    }

    const mediaQueryPortrait = window.matchMedia('(orientation: portrait)')
    const mediaQueryLandscape = window.matchMedia('(orientation: landscape)')

    if (mediaQueryPortrait.matches) {
      setOrientation('portrait')
    } else if (mediaQueryLandscape.matches) {
      setOrientation('landscape')
    }

    mediaQueryPortrait.addListener(handleOrientationChange)
    mediaQueryLandscape.addListener(handleOrientationChange)

    return () => {
      mediaQueryPortrait.removeListener(handleOrientationChange)
      mediaQueryLandscape.removeListener(handleOrientationChange)
    }
  }, [])

  return { getOrientation }
}
