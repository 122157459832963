import { Wrapper } from '../styles'

const SplashScreen = () => {
  return (
    <Wrapper>
      <div className="flex flex-col items-center justify-center max-w-[500px]">
        <h1 className="text-center text-dark text-4xl font-bold mb-4">
          Welcome to Mile Auto's vehicle photo system!
        </h1>
        <p className="text-center text-normal text-lg">
          Check your email and click on the provided link to start the process.
        </p>
      </div>
    </Wrapper>
  )
}

export default SplashScreen
