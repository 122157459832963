import { baseUrl } from 'Constants/keys'
import { buildBody, useGetAPIRequest } from 'Utils/apiRequestsGen'

export const useGetTaskListQuery = (token: string) => {
  return useGetAPIRequest<any>({
    url: baseUrl + 'task/list',
    options: {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      method: 'POST',
      ...buildBody({ token })
    }
  })
}

export const useUpdateTaskMutation = () => {
  return useGetAPIRequest<any>({
    url: baseUrl + 'task/update',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    isMutation: true
  })
}