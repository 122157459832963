import { forwardRef, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import logo from 'Assets/mileauto_logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LogoContainer } from './styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide
} from '@mui/material'
import { type TransitionProps } from '@mui/material/transitions'
import { themeColors } from '../../themes'
import { isMobile } from 'react-device-detect'
import {isMile} from "../../Utils/envCheck";

const Transition = forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function MenuAppBar () {
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const START_HOURS = 6
  const END_HOURS = 18

  const today = new Date()
  // not sunday nor saturday
  const isWeekDay = today.getDay() !== 0 || today.getDay() !== START_HOURS
  const isOfficeHour = today.getHours() >= 9 && today.getHours() <= END_HOURS
  const isPhoneAvailable = isWeekDay && isOfficeHour

  return (
    <Box sx={{
      flexGrow: 1,
      '@media(orientation: landscape)': {
        display: isMobile ? 'none' : 'inherit'
      }
    }}>
      <AppBar
        position="static"
        style={{
          display: 'flex',
          flex: 1,
          borderRadius: isMile() ? 8 : 0,
          backgroundColor: 'white'
        }}
      >
        <Toolbar
          variant={'dense'}
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto 1fr',
            gridTemplateAreas: "'left center right'",
            minHeight: '36px',
            width: '100%'
          }}
        >
          <div
            style={{
              gridArea: 'left',
              justifySelf: 'start',
              color: themeColors.mile.success
            }}
          >
            <FontAwesomeIcon
              icon={['fas', 'bars']}
            />
          </div>
          <LogoContainer
            style={{
              gridArea: 'center',
              justifySelf: 'center',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src={logo} alt="logo" />
          </LogoContainer>
          <div
            style={{
              gridArea: 'right',
              justifySelf: 'end',
              color: themeColors.mile.success
            }}
          >
            <FontAwesomeIcon
              aria-controls="call-menu"
              aria-haspopup="true"
              onClick={handleClickOpen}
              icon={['fas', 'phone']}
            />
          </div>
        </Toolbar>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
          >
            Contact
          </DialogTitle>
          <DialogContent
          >
            <p className="mb-2">
              Our Customer Care team is available Monday through Friday from 9
              AM to 6 PM across the continental United States.
            </p>
            {!isPhoneAvailable && (
              <p>
                If you would like to contact our team outside of those hours,
                you may email us by clicking below.
              </p>
            )}
          </DialogContent>
          <DialogActions
          >
            <Button
              className="call-button"
              href={isMile() ? 'tel:18886453001' : 'tel:18004004128' }
              onClick={handleClose}
              disabled={!isPhoneAvailable}
            >
              Via Call
            </Button>
            <Button
              className="email-button"
              href={isMile() ? 'mailto:customercare@mileauto.com' : 'mailto:customercare@porscheautoinsurance.com'}
              onClick={handleClose}
            >
              Via Email
            </Button>
          </DialogActions>
        </Dialog>
      </AppBar>
    </Box>
  )
}
