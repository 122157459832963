import { type FC, type ReactNode, createContext, useState } from 'react'
import { INITIAL_STATE, STORED_STATE_NAME } from 'Constants/state'
import {
  type MVODStateType,
  type MVODStateContextType,
  type MVODStoredState
} from 'Typings/state'

const getStoredState = (): MVODStateType => {
  const storedState = localStorage.getItem(STORED_STATE_NAME)
  if (!storedState) return INITIAL_STATE.state
  return JSON.parse(storedState)
}

const setStateToStore = (newState: MVODStoredState) => {
  localStorage.setItem(STORED_STATE_NAME, JSON.stringify(newState))
}

export const MVODStateContext =
  createContext<MVODStateContextType>(INITIAL_STATE)

interface MVODStateProviderProps {
  children: ReactNode | ReactNode[]
}

export const MVODStateProvider: FC<MVODStateProviderProps> = ({ children }) => {
  const [state, setState] = useState<MVODStateType>({
    ...INITIAL_STATE.state,
    ...getStoredState()
  })
  const updateState = (s: MVODStateType) => { setState(s) }
  const updateStateItem = (vs: MVODStateType) => {
    setState((prev) => ({ ...prev, ...vs }))
  }
  const updateStoredState = (ss: MVODStoredState) => {
    setState((prev) => {
      setStateToStore({ ...getStoredState(), ...ss })
      return { ...prev, ...ss }
    })
  }

  return (
    <MVODStateContext.Provider
      value={{
        state,
        updateState,
        updateStateItem,
        updateStoredState
      }}
    >
      {children}
    </MVODStateContext.Provider>
  )
}
