import styled from '@emotion/styled'
import { themeColors } from 'themes'

export const Wrapper = styled.div({
  background: themeColors.lightBlue
})

export const Container = styled.div({
  padding: '20px 30px'
})

export const ContentContainer = styled.div({
  margin: '40px 0px'
})
