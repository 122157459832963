import { MVODStateProvider } from 'Services/stateProvider'
import { Routes } from 'Routes/routes'
import NavBar from 'Components/NavBar'
import { Wrapper, Container, ContentContainer } from './styles'
import { ToastMessage } from 'Components/ToastMessage'

const App = () => {
  console.log('MVODv1.0')
  return (
    <Wrapper>
      <MVODStateProvider>
        <ToastMessage />
        <Container>
          <NavBar />
          <ContentContainer>
            <Routes />
          </ContentContainer>
        </Container>
      </MVODStateProvider>
    </Wrapper>
  )
}

export default App
