import { Navigate, type RouteObject } from 'react-router-dom'

import Camera from 'Containers/Camera'
import Home from 'Containers/Home'
import TakePhoto from 'Containers/TakePhoto'
import SplashScreen from 'Containers/SplashScreen'
import Success from 'Containers/Success'

const RESTRICTED_ROUTES: RouteObject[] = []

const PUBLIC_ROUTES: RouteObject[] = [
  { path: '/', element: <Home /> },
  { path: '/:key', element: <Home /> },
  { path: '/index', element: <SplashScreen /> },
  { path: '/photo', element: <TakePhoto /> },
  { path: '/camera', element: <Camera /> },
  { path: '/success', element: <Success /> },
  { path: '*', element: <Navigate to="/" /> }
]

const AUTHENTICATED_ROUTES: RouteObject[] = [
  { path: '/', element: <Home /> },
  { path: '*', element: <Navigate to="/" /> }
]

export { PUBLIC_ROUTES, AUTHENTICATED_ROUTES, RESTRICTED_ROUTES }
