import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { Wrapper } from '../styles'
import handsImage from 'Assets/mvod-hands.png'
import { useContext, useEffect } from 'react'
import { MVODStateContext } from 'Services/stateProvider'

const TakePhoto = () => {
  const {
    state: {
      selectedTask: task
    }
  } = useContext(MVODStateContext)
  const navigate = useNavigate()
  // const imageUpload = useRef(null);
  // const [file, setFile] = useState(image)

  useEffect(() => {
    if (task && !task.id) navigate('/')
  }, [task])

  const handleTakePhotoClick = () => {
    // if (!isMobile) {
    //   // @ts-expect-error "ref must be null to activate"
    //
    // } else {
      navigate('/camera');
    // }
  }

  return (
    <Wrapper>
      <div className="flex flex-col items-center justify-center max-w-[500px]">
        <div className="mx-4">
          <h1 className="text-center text-dark text-4xl font-bold mb-4">
            Get ready...
          </h1>

          <p className="text-center text-normal text-lg">
            Let's take a photo of the odometer of your <b style={{ whiteSpace: 'nowrap' }}>{task?.vehicle.name}</b>. When you’re ready, tap the button to
            open your camera.
          </p>
        </div>

        <img
          src={handsImage}
          alt="hands with a phone"
          className="w-[100vw] max-w-[500px]"
        />
        {/*<BrowserView>*/}
        {/*  <input type='file' onChange={handleFileChange} ref={imageUpload} style={{ display: 'none' }} />*/}
        {/*</BrowserView>*/}
        <Button
          className="w-[90%] max-w-[250px]"
          onClick={handleTakePhotoClick}
          variant="contained"
        >
          Take a photo
        </Button>
      </div>
    </Wrapper>
  )
}

export default TakePhoto
