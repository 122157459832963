import {AppHost} from "../Typings/strings";

const mileStr: AppHost = "MileAuto";
const porscheStr: AppHost = "Porsche";

export function isMile() {
    return process.env.REACT_APP_HOST === mileStr;
}

export function isPorsche() {
    return process.env.REACT_APP_HOST === porscheStr;
}