import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import {
  awsIdentityPoolId,
  s3Bucket,
  s3Region
} from 'Constants/keys'
import { type TaskType } from 'Typings/state'

const createS3Filename = ({
  typeName,
  userId
}: {
  typeName: string
  userId: string
}) => {
  const newDate = new Date() // this will parse the format
  let day = newDate.getDate().toString()
  day = day.length === 1 ? (day = `0${day}`) : day
  let month = (newDate.getMonth() + 1).toString()
  month = month.length === 1 ? (month = `0${month}`) : month
  let hour = newDate.getHours().toString()
  hour = hour.length === 1 ? (hour = `0${hour}`) : hour
  let minute = newDate.getMinutes().toString()
  minute = minute.length === 1 ? (minute = `0${minute}`) : minute
  let second = newDate.getSeconds().toString()
  second = second.length === 1 ? (second = `0${second}`) : second

  let s3FileName = `${typeName}-${newDate.getFullYear()}-${month}-${day}-${hour}-${minute}-${second}.jpg`
  s3FileName = `mverity/${userId}/${s3FileName}`
  return s3FileName
}

export const uploadImageToS3 = async ({
  blobData,
  task,
  fileType
}: {
  blobData: Blob
  task: TaskType,
  fileType: string | undefined
}) => {
  const albumBucketName: string = s3Bucket
  const bucketRegion: string = s3Region
  const IdentityPoolId: string = awsIdentityPoolId

  const s3 = new S3Client({
    region: bucketRegion,
    apiVersion: '2006-03-01',
    credentials:  fromCognitoIdentityPool({
      identityPoolId: IdentityPoolId,
      clientConfig: { region: bucketRegion }
    }),
  });
  const filename = createS3Filename({
    userId: task.id,
    typeName: task.record_type
  })
  const params = {
    Key: filename,
    Bucket: albumBucketName,
    ContentType: `${fileType || 'image/jpeg'}`,
    Body: blobData
  }
  const location:string = 'https://s3.amazonaws.com/' + albumBucketName + '/' + filename;
  if (!params.Body) {
    throw Error("cannot locate image body");
  }

  const command = new PutObjectCommand(params);

  const s3Res = await s3.send(command)
  return {Location: location, ...s3Res}
}
